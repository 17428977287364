require('../css/app.scss');

document.addEventListener("DOMContentLoaded", function(event) {
    const navBarMenuIcon = document.querySelector('.side-menu--icon');
    navBarMenuIcon.addEventListener('click', openNav, false);
    const sideMenuIcon = document.querySelector('.side-bar--menu-icon');
    sideMenuIcon.addEventListener('click', openNav, false);

    const closeSideMenuIcon = document.querySelector('.side-menu--close');
    closeSideMenuIcon.addEventListener('click', closeNav, false);

    function openNav() {
        document.querySelector('.side-menu').classList.add('side-menu__shown');
        document.querySelector('.main').style.opacity = '.5';
        document.querySelector('.header-wrapper').style.opacity = '.5';
    }

    function closeNav() {
        document.querySelector('.side-menu').classList.remove('side-menu__shown');
        document.querySelector('.main').style.opacity = '1';
        document.querySelector('.header-wrapper').style.opacity = '1';
    }
});
